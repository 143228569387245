import React, { useState } from "react";

import { Container, Row, Col, Card } from "reactstrap";

import Slider from "react-slick";
import { sponsors, newsponsors } from "views/content";

function Sponsor() {
  const [Sponsors2024, setSponsors2024] = useState([])
  const settings = {
    dots: true,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 4000,
    slidesToShow: 4,
    slidesToScroll: 4,
    rows: 2,
    className: "p-10",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  React.useEffect(() => {
    // get the spekers and sponsers from the API
    fetch(`https://api.khaleejtimesevents.com/dhf/data.json?v=3${new Date()}`, {
      method: "get",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.sponsors2024 && data.sponsors2024.length > 0) {
          setSponsors2024(data.sponsors2024);
        }
      })
      .catch((err) => console.log(err));
    // get the spekers and sponsers from the API  
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  }, []);

  return (
    <>
      <div
        style={{
          backgroundColor: "e0e4e5",
          // backgroundImage: "linear-gradient(315deg, #d4418e 0%, #0652c5 74%)",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        {" "}
        <Container className="border-top">
          <Row className="justify-content-center">
            <Col lg={12}>
              <h1 className="text-center text-main section-title my-5">
                <b>SPONSORS & PARTNERS 2024 </b>
              </h1>
            </Col>

            {Sponsors2024.map((s) => (
              <Col style={{ margin: '0px auto' }} key={s.id} lg={4}>
                <div className="text-center" style={{ border: '1px solid #dedede', marginBottom: '20px', padding: '10px' }}>
                  <img src={s.img} alt="" style={{ maxWidth: '100%' }} />
                  <h2 style={{ fontWeight: 700, color: '#082a6c' }}>{s.title}&nbsp;</h2>
                </div>
              </Col>
            ))}

            <Col lg={12}>
              <h1 className="text-center text-main section-title my-5">
                <b>PAST SPONSORS & PARTNERS</b>
              </h1>
            </Col>

            <Col lg={12} xs={11} className={"ml-auto mr-auto"}>
              <Slider {...settings}>
                {[...newsponsors, ...sponsors, ...associations, ...s_associations].map(
                  (sponsor, index) => (
                    // <Col lg={3} key={index}>
                    <a href={`/sponsors/${sponsor.pageLink}`}>
                      <Container fluid>
                        <Row>
                          {/* <Col
                          lg={12}
                          className="text-center text-dark border-bottom mb-2"
                          style={{
                            padding: "1rem 0rem",
                          }}
                        >
                          <h5
                            className="text-dark text-400 text-uppercase"
                            style={{ margin: 0, padding: 0 }}
                            dangerouslySetInnerHTML={{
                              __html: sponsor.type,
                            }}
                          ></h5>
                        </Col> */}
                          <Col lg={12}>
                            <Card style={{ borderRadius: "0" }}>
                              <img
                                src={require(`assets/sponsors/${sponsor.image}`)}
                                alt={sponsor.name}
                                width="100%"
                                style={{ borderRadius: "0" }}
                              />
                            </Card>
                          </Col>
                        </Row>
                      </Container>
                    </a>
                    // </Col>
                  )
                )}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Sponsor;

export const associations = [
  {
    name: "UAE MOHAP",
    pageLink: "UAE MOHAP",
    type: "SUPPORTING ASSOCIATION",
    image: "mhp.png",
    text: [``],
    link: "",
    size: "80%",
    grid: 4,
  },
];

export const s_associations = [
  {
    name: "Dubai Healthcare City Authority",
    pageLink: "dhca",
    type: "SUPPORTING ASSOCIATION",
    image: "dhca.png",
    text: [``],
    link: "",
    size: "80%",
    grid: 4,
  },
];

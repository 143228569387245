import React, { useState } from "react";
import { Container, Row, Col, Card, Modal } from "reactstrap";
import SpeakerModal from "components/SpeakerModal.js";
// import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import { speakers, pastspeakers } from "views/content";

function SpeakerGrid() {
  const [show, setShow] = useState(false);
  const [Speakers2024, setSpeakers2024] = useState([])
  const [Speakers2024VIP, setSpeakers2024VIP] = useState([])
  const [open, toggleOpen] = React.useState({
    open: false,
    speaker: {},
  });
  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 8000,
    slidesToShow: 4,
    rows: 2,
    slidesToScroll: 1,
    className: "p-10",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const updateModal = (e, speaker) => {
    e.preventDefault();
    toggleOpen({ open: !open.open, speaker: speaker });
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    // get the spekers and sponsers from the API
    fetch(`https://api.khaleejtimesevents.com/dhf/data.json?v=${new Date()}`, {
      method: "get",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.speakers2024 && data.speakers2024.length > 0) {
          setSpeakers2024(data.speakers2024);
        }
        if (data.speakers2024VIP && data.speakers2024VIP.length > 0) {
          setSpeakers2024VIP(data.speakers2024VIP);
        }
      })
      .catch((err) => console.log(err));
    // get the spekers and sponsers from the API  
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  }, []);

  function SpeakerCard({ speaker }) {
    return (
      <Card
        className="card-background-image mx-auto p-3 text-white"
        style={{
          borderRadius: 0,
          boxShadow: "none",
          background: "transparent",
          cursor: 'pointer',
        }}
        onClick={() => {
            setShow(speaker);
            console.log(speaker);
          }
        }
      >
        <Container fluid>
          <Row>
            <Col xs={12} className="px-0 text-center">
              <div>
                <img
                  src={speaker.imageHttp ? speaker.imageHttp : `assets/speakers/${speaker.image}`}
                  className="rounded-circle"
                  width="100%"
                  alt="speaker"
                />
              </div>
            </Col>

            <Col xs={12} className="text-center">
              <h3 className="text-700 text-uppercase mt-2 m-0 d-block px-0">{speaker.name}</h3>

              <small className="author m-0">{speaker.title}</small>
              <h4 className="text-700  text-uppercase m-0 d-block px-0">{speaker.company}</h4>
            </Col>
          </Row>
        </Container>
      </Card>
    );
  }

  return (
    <>


    <div className={`modal ${show ? 'show' : ''}`} tabIndex="-1" style={{ overflow: 'auto', display: show ? 'block' : 'none' }} aria-hidden={!show}>
        <div
          className="modal-dialog"
          style={{
            maxWidth: show.html && show.html !== '' ? '80%' : 'max-content',
            minWidth: show.html && show.html !== '' ? '250px' : 'auto',
            margin: '2vh auto',
          }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <div>
                <h3 className="modal-title" style={{ fontWeight: 1000, color: '#002773' }}>{show.name}</h3>
              </div>
              <button type="button" className="btn btn-danger btn-sm" aria-label="Close" onClick={() => { setShow(false); }}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                {show.html && show.html !== '' ? (
                  <>
                    <div className="col-md-5 text-center">
                      <img
                        src={show.imageHttp ? show.imageHttp : `assets/speakers/${show.image}`}
                        alt={show.name}
                        className="img-fluid mb-3"
                        style={{ width: '100%', maxHeight: '70vh', objectFit: 'contain', border: '2px solid #f5f5f5' }}
                      />
                      <h4 style={{ fontWeight: 900, color: 'green' }}>
                        {show.title}
                        <br />
                        <br />
                        {show.company}
                      </h4>
                    </div>
                    <div className="col-md-7">
                      <div
                        className="mt-3"
                        dangerouslySetInnerHTML={{ __html: show.html }}
                        style={{ maxHeight: '70vh', overflow: 'auto' }}
                      />
                    </div>
                  </>
                ): (
                  <>
                    <div className="col-md-12 text-center">
                      <img
                        src={show.imageHttp ? show.imageHttp : `assets/speakers/${show.image}`}
                        alt={show.name}
                        className="img-fluid mb-3"
                        style={{ maxWidth: '100%', maxHeight: '70vh', objectFit: 'contain', border: '2px solid #f5f5f5' }}
                      />
                      <h4 style={{ fontWeight: 900, color: 'green' }}>
                        {show.title}
                        <br />
                        <br />
                        {show.company}
                      </h4>
                    </div>
                    <div className="col-md-12">
                      <div
                        className="mt-3"
                        dangerouslySetInnerHTML={{ __html: show.html }}
                        style={{ maxHeight: '70vh', overflow: 'auto' }}
                      />
                    </div>
                  </>
                )}

              </div>
              
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          background: "linear-gradient(142deg, rgba(32,120,183,1) 0%, rgba(51,180,171,1) 100%)",
        }}
        className="section"
        data-parallax={true}
      >
        <div className="filter" />
        <Container>
          <Row>
            <Col lg={12} className="mb-5">
              <h1 className="text-white text-left section-title text-center">
                <b>SPEAKERS 2024</b>
              </h1>
            </Col>

            {Speakers2024VIP.map((speaker) => (
              <Col md={4} style={{ margin: '0px auto' }} key={speaker.name}>
                <SpeakerCard speaker={speaker} />
              </Col>
            ))}

            <Col lg={12} className="mb-5"></Col>
            {Speakers2024.map((speaker) => (
              <Col md={3} style={{ margin: '0px auto' }} key={speaker.name}>
                <SpeakerCard speaker={speaker} />
              </Col>
            ))}

            <Col lg={12} className="mb-5">
              <h1 className="text-white text-left section-title text-center">
                <b>PAST SPEAKERS</b>
              </h1>
            </Col>

            <Col xs={11} className={"mx-auto"}>
              <Slider {...settings}>
                {[...speakers, ...pastspeakers].map((speaker, index) => (
                  <SpeakerCard speaker={speaker} />
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>

        <Modal isOpen={open.open} toggle={(e) => updateModal(e)} style={{}}>
          <div className="section profile-content">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => updateModal(e)}
            >
              <span aria-hidden={true}>×</span>
            </button>
            <SpeakerModal speaker={open.speaker} />
          </div>
        </Modal>
      </div>
    </>
  );
}

export default SpeakerGrid;

// import React from "react";
// import ScrollAnimation from "react-animate-on-scroll";
// import {
//   Button,
//   Card,
//   CardBody,
//   Col,
//   Container,
//   Row,
//   TabContent,
//   TabPane,
//   UncontrolledCollapse,
// } from "reactstrap";

// function Agenda() {
//   function AgendaCard({ data }) {
//     return data.map((d, i) => (
//       <Col lg={12}>
//         <ScrollAnimation animateIn="fadeInDown" animateOnce={true} duration={0.4}>
//           <Card style={{ backgroundColor: "#1677bd" }}>
//             <CardBody>
//               <Container>
//                 <Row>
//                   <Col lg={12} className="align-self-center text-white">
//                     <h2 className="text-400">
//                       <span className="mr-4">{d.time}</span> {d.title}
//                     </h2>
//                   </Col>

//                   <Col lg={12}>
//                     {d.description && (
//                       <h3
//                         className="text-400 text-white"
//                         dangerouslySetInnerHTML={{ __html: d.description }}
//                       />
//                     )}
//                   </Col>
//                 </Row>
//               </Container>
//             </CardBody>
//           </Card>
//         </ScrollAnimation>
//       </Col>
//     ));
//   }

//   return (
//     <>
//       <div
//         style={{
//           backgroundColor: "#fff",
//         }}
//         className="section"
//         data-parallax={true}
//       >
//         <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
//           <Container>
//             <Row>
//               <Col className="ml-auto mr-auto" md={12}>
//                 <h1 className="title mb-1 section-title text-center">
//                   <b>Agenda</b>
//                 </h1>
//                 <br />
//               </Col>
//               <Col md="12">
//                 <TabContent activeTab={"1"}>
//                   <TabPane tabId="1">
//                     <Container>
//                       <Row>
//                         <AgendaCard data={content1} />
//                         <Col lg={12} className={"text-center"}>
//                           <Button href="/register" className="btn" color="primary" size="md">
//                             Register Now
//                           </Button>
//                         </Col>
//                       </Row>
//                     </Container>
//                   </TabPane>
//                 </TabContent>
//               </Col>
//             </Row>
//           </Container>
//         </ScrollAnimation>
//       </div>
//     </>
//   );
// }
import React, { useContext } from "react";
import { Container, Button, Row, Col, Card, CardBody } from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";
import { groupBy } from "views/content";
import { DataContext } from "DataContainer";
import moment from "moment";

function Agenda() {
  const { agenda } = useContext(DataContext);
  const dayWiseAgenda = groupBy("day")(agenda);
  function AgendaCard({ data }) {
    return data.map((d, index) => (
      <Col lg={12}>
        <ScrollAnimation animateIn="fadeInDown" animateOnce={true} duration={0.4}>
          <Card style={{ backgroundColor: "#1677bd" }}>
            <CardBody>
              <Container>
                <Row className="py-2">
                  <Col lg={2}>
                    <h2 className="text-400 text-white">
                      <b>
                        {moment.utc(d.startAt).format("HH:mm")} -{" "}
                        {moment.utc(d.endAt).format("HH:mm")}{" "}
                      </b>{" "}
                    </h2>
                  </Col>
                  <Col lg={9} className="text-white">
                    <h2 className="text-400 text-white m-0">
                      <b>{d.title}</b>
                    </h2>
                    
                    <h2
                      className="text-400 text-white"
                      id="agendaDescription"
                      style={{ color: "#fff !important" }}
                      dangerouslySetInnerHTML={{ __html: d.description }}
                    />
                  </Col>
                  <Col lg={9}></Col>
                </Row>
              </Container>
            </CardBody>
          </Card>
        </ScrollAnimation>
      </Col>
    ));
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#ffffff",
        }}
        className="section"
        data-parallax={true}
      >
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <Container>
            <Row>
              {/* <Col className="ml-auto mr-auto " md={12}>
                <h1 className="section-title mb-4 text-700 text-main text-center">
                  <b>AGENDA</b>
                </h1>
                <br />
              </Col> */}
              <Col md="12">
                {/* <div className="nav-tabs-navigation">
                  <div className="nav-tabs-wrapper">
                    <h2 className="text-700 text-main">(Gulf Standard Time)</h2>
                  </div>
                </div> */}

                <Container>
                  <Row>
                    <AgendaCard data={dayWiseAgenda[1] || []} />
                    <Col lg={12} className={"text-center"}>
                      <Button href="/register" className="btn" color="primary" size="md">
                        Register Now
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </ScrollAnimation>
      </div>
    </>
  );
}

export default Agenda;

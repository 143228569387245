import React from "react";
import Navbar from "components/Navbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import Footer from "components/Footer.js";
import TextGrid from "components/TextGrid.js";
import Agenda from "components/Agenda.js";
// import WhoAttend from "components/WhoAttend.js";
import KeyTopics from "components/KeyTopics.js";
import SpeakerCard from "components/SpeakerCard.js";
import SpeakerCardNew from "components/SpeakerCardNew.js";

import Sponsor from "components/Sponsor.js";
import { pastspeakers, speakers, sponsorsPage, eventAgendas } from "./content";
import WhyAttend from "components/WhyAttend";
import WhoSponsor from "components/WhoSponsor";
// import WhySponsor from "components/WhySponsor";
import Stats from "components/Stats";
import Photos from "components/Photos";

function Home() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });

  return (
    <>
      <div id="outer-container">
        <Navbar home={true} />
        <section id="home" />
        <LandingPageHeader />
        <Stats />

        <main id="page-wrap">
          <section id="about" />
          <TextGrid />
          <KeyTopics />
          <section id="agenda" />
          <section className="event-agenda mx-auto my-5">
            <h1 className="text-center section-title px-3 pb-5 mb-4">Event Agenda</h1>

            <table className="eventAgendaTable table table-bordered table-striped table-hover mx-auto shadow-sm">
              <thead>
                <tr className="text-white bg-primary">
                  <th className="text-center" style={{ fontSize: '20px' }}>Time</th>
                  <th style={{ fontSize: '20px' }}>Session</th>
                </tr>
              </thead>

              <tbody>
                {
                  eventAgendas.map((agenda, index) => (
                    <tr key={index}>
                      <td className="text-center px-3 px-sm-4 px-md-5" style={{ fontSize: '20px', minWidth: '200px' }}>
                        <div className="text-nowrap" dangerouslySetInnerHTML={{ __html: agenda.time }}></div>
                      </td>
                      <td style={{ fontSize: '16px' }}>
                        <div dangerouslySetInnerHTML={{ __html: agenda.session }}></div>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>

            {/* <div className="text-center mt-5">
              <Button className="px-3 py-2 rounded-3" color="primary" href="/registration/download-agenda" >
                <b className="m-0 text-600" style={{ color: "#fff", letterSpacing: '1px', textTransform: 'none', fontSize: '1rem', }}>Download Agenda</b>
              </Button>
            </div> */}
          </section>

          {/* <WhoAttend/> */}
          <div className="main">
            <section id="speakers" />
            <SpeakerCardNew />
            <SpeakerCard speakers={speakers} pastspeakers={pastspeakers} />
          </div>
          <Agenda />
          <WhyAttend />
          <WhoSponsor />
          <section id="sponsor" />
          <Sponsor sponsors={sponsorsPage} />
          <section id="2022Glimpses" />
          <Photos />
          <Footer />
        </main>
      </div>
    </>
  );
}

export default Home;

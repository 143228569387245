import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import Agenda from "components/Agenda";

import Footer from "components/Footer.js";
import Sponsor from "components/Sponsor.js";
import { sponsors, eventAgendas } from "./content";
// import Testimonials from "components/Testimonials.js";

function AgendaPage() {
  return (
    <>
      <Navbar />

      <ProfilePageHeader title="" bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>

      <section className="event-agenda mx-auto my-5">
            <h1 className="text-center section-title px-3 pb-5 mb-4">Event Agenda</h1>


      <table className="eventAgendaTable table table-bordered table-striped table-hover mx-auto shadow-sm">
              <thead>
                <tr className="text-white bg-primary">
                  <th className="text-center" style={{ fontSize: '20px' }}>Time</th>
                  <th style={{ fontSize: '20px' }}>Session</th>
                </tr>
              </thead>

              <tbody>
                {
                  eventAgendas.map((agenda, index) => (
                    <tr key={index}>
                      <td className="text-center px-3 px-sm-4 px-md-5" style={{ fontSize: '20px', minWidth: '200px' }}>
                        <div className="text-nowrap" dangerouslySetInnerHTML={{ __html: agenda.time }}></div>
                      </td>
                      <td style={{ fontSize: '16px' }}>
                        <div dangerouslySetInnerHTML={{ __html: agenda.session }}></div>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            </section>
        <Agenda />
      </div>
      {/* <Testimonials /> */}
      <Sponsor sponsors={sponsors} />
      <Footer />
    </>
  );
}

export default AgendaPage;
